import { ActionTypes } from '../actions'
import _ from 'lodash'

const {
  FETCH_LEAGUE_METADATA_REQUEST,
  FETCH_LEAGUE_METADATA_RECEIVED,
  OPEN_EDIT_CUSTOM_FIELDS_POPUP,
  CLOSE_EDIT_CUSTOM_FIELDS_POPUP,
  OPEN_ADD_GOLFERS_MANUALLY_POPUP,
  CLOSE_ADD_GOLFERS_MANUALLY_POPUP,
  NEW_MEMBER_FIELD_CHANGED,
  ADD_MORE_GOLFERS,
  CURRENT_CUSTOM_FIELDS_CHANGED,
  ADD_NEW_MEMBERS_REQUEST,
  ADD_NEW_MEMBERS_RECEIVED,
  TOGGLE_TERMS_AND_PRIVACY,
} = ActionTypes

const resetNewMembers = () => {
  const newMembers = []
  for (let i = 0; i < 5; i++) {
    newMembers.push({ lastName: '', firstName: '', email: '', handicapNetworkId: '', hi18Hole: '', hi9Hole: '', divisions: '', customFields: {} })
  }
  return newMembers
}

const initialState = {
  membersMetadata: [],
  affiliationAnswers: [],
  sortedCountries: [],
  isLoading: false,
  showEditCustomFieldsPopup: false,
  showAddGolfersManuallyPopup: false,
  playerIndex: 0,
  leagueId: '',
  dateFormat: 'mm/dd/yyyy',
  newMembers: resetNewMembers(),
  nrCustomFields: [],
  currentCustomFields: {},
  disableSaveFields: true,
  isNewMembersSaving: false,
  termsAndPrivacyChecked: {
    addGolfersManually: false,
  },
}

const addNewMembers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEAGUE_METADATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_LEAGUE_METADATA_RECEIVED:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      }
    case OPEN_EDIT_CUSTOM_FIELDS_POPUP:
      return {
        ...state,
        showEditCustomFieldsPopup: true,
        playerIndex: action.playerIndex,
        currentCustomFields: state.newMembers[action.playerIndex].customFields,
        disableSaveFields: Object.keys(state.newMembers[action.playerIndex].customFields).length <= 0,
      }
    case CLOSE_EDIT_CUSTOM_FIELDS_POPUP:
      return {
        ...state,
        showEditCustomFieldsPopup: false,
      }
    case OPEN_ADD_GOLFERS_MANUALLY_POPUP:
      return {
        ...state,
        showAddGolfersManuallyPopup: true,
      }
    case CLOSE_ADD_GOLFERS_MANUALLY_POPUP:
      return {
        ...state,
        showAddGolfersManuallyPopup: false,
        newMembers: action.resetMembers ? resetNewMembers() : state.newMembers,
        nrCustomFields: action.resetMembers ? [] : state.nrCustomFields,
        termsAndPrivacyChecked: {
          addGolfersManually: action.resetMembers ? false : state.termsAndPrivacyChecked.addGolfersManually,
        },
      }
    case NEW_MEMBER_FIELD_CHANGED: {
      const newMembersClone = _.clone(state.newMembers)
      newMembersClone[action.index][action.name] = action.value
      const nrCustomFields = _.clone(state.nrCustomFields)
      if (action.name === 'customFields'){
        nrCustomFields[action.index] = Object.values(newMembersClone[action.index].customFields).filter(x => x !== '' && x !== []).length
      }
      return {
        ...state,
        newMembers: newMembersClone,
        nrCustomFields: nrCustomFields,
      }
    }
    case CURRENT_CUSTOM_FIELDS_CHANGED: {
      const customFieldsClone = _.clone(state.currentCustomFields)
      if (action.category === 'array') {
        customFieldsClone[action.id] = customFieldsClone[action.id] ? customFieldsClone[action.id] : []
        if (action.value && action.answer) {
          customFieldsClone[action.id].push(action.answer)
        } else {
          customFieldsClone[action.id] = customFieldsClone[action.id].filter(x => x !== action.answer)
          if (customFieldsClone[action.id].length === 0){
            delete customFieldsClone[action.id]
          }
        }
      } else {
        if (action.value === ''){
          delete customFieldsClone[action.id]
        } else {
          if (action.category === 'large_string') {
            customFieldsClone[action.id] = { value: action.value, answerId: action.answerId }
          } else {
            customFieldsClone[action.id] = action.value
          }
        }
      }
      return {
        ...state,
        currentCustomFields: customFieldsClone,
        disableSaveFields: Object.keys(customFieldsClone).length <= 0,
      }
    }
    case ADD_MORE_GOLFERS: {
      const newMembersClone = _.clone(state.newMembers)
      for (let i = 0; i < 5; i++) {
        newMembersClone.push({ lastName: '', firstName: '', email: '', handicapNetworkId: '', hi18Hole: '', hi9Hole: '', divisions: '', customFields: {} })
      }
      return {
        ...state,
        newMembers: newMembersClone,
      }
    }
    case ADD_NEW_MEMBERS_REQUEST:
      return {
        ...state,
        isNewMembersSaving: true,
      }
    case ADD_NEW_MEMBERS_RECEIVED:
      return {
        ...state,
        isNewMembersSaving: false,
        showAddGolfersManuallyPopup: false,
        newMembers: resetNewMembers(),
        nrCustomFields: [],
        termsAndPrivacyChecked: {
          addGolfersManually: false,
        },
      }
    case TOGGLE_TERMS_AND_PRIVACY:
      return {
        ...state,
        termsAndPrivacyChecked: {
          ...state.termsAndPrivacyChecked,
          [action.toggleType]: action.checked,
        },
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default addNewMembers
