import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import RegistrationMemberOrGuest from './custom_field_components/registration_member_or_guest_component'
import AffiliationComponent from './custom_field_components/affiliation_component'
import DateComponent from './custom_field_components/date_component'
import BooleanComponent from './custom_field_components/boolean_component'
import SingleStringComponent from './custom_field_components/single_string_component'
import StringComponent from './custom_field_components/string_component'
import CountrySelectComponent from './custom_field_components/country_select_component'
import TextareaComponent from './custom_field_components/textarea_component'
import DefaultComponent from './custom_field_components/default_component'
import ArrayComponent from './custom_field_components/array_component'
import LargeStringComponent from './custom_field_components/large_string_component'

export class CustomFieldComponent extends Component {
  constructor(props) {
    super(props)
  }
  
  getCustomField(){
    const answer = this.props.playerCustomFields[this.props.field.id]
    switch (this.props.field.name.toLowerCase()){
      case 'registration member or guest':
        return <RegistrationMemberOrGuest {...this.props} answer={answer}></RegistrationMemberOrGuest>
      case 'affiliation':
        return <AffiliationComponent {...this.props} answer={answer}></AffiliationComponent>
      default: {
        switch (this.props.field.category){
          case 'date':
            return <DateComponent {...this.props} answer={answer}></DateComponent>
          case 'boolean':
            return <BooleanComponent {...this.props} answer={answer}></BooleanComponent>
          case 'single_string':
            return <SingleStringComponent {...this.props} answer={answer}></SingleStringComponent>
          case 'string':
            return <StringComponent {...this.props} answer={answer}></StringComponent>
          case 'country_select':
            return <CountrySelectComponent {...this.props} answer={answer}></CountrySelectComponent>
          case 'array':
            return <ArrayComponent {...this.props} answer={answer}></ArrayComponent>
          case 'textarea':
            return <TextareaComponent {...this.props} answer={answer}></TextareaComponent>
          case 'large_string':
            return <LargeStringComponent {...this.props} answer={answer}></LargeStringComponent>  
          default:
            return <DefaultComponent {...this.props} answer={answer}></DefaultComponent>
        } 
      }
    }
  }

  render() {
    let rowClass = ''
    if ([ 'boolean', 'string', 'array' ].indexOf(this.props.field.category) > -1 || this.props.field.name === 'registration member or guest'){
      rowClass = 'radio'
    } else if (this.props.field.name === 'affiliation') {
      rowClass = 'affiliation btsp_chosen'
    }
    return <Col xs={12} sm={6}><div className={`row ${rowClass}`}><Col xs={12} md={4} className="text_label"><label>{ this.props.field.name === 'Registration Member or Guest' ? 'Member or Guest' : this.props.field.name }</label></Col>{ this.getCustomField() }</div></Col>
  }
}

CustomFieldComponent.propTypes = {
  changeMembersMetadata: PropTypes.func,
  field: PropTypes.object,
  affiliationAnswers: PropTypes.array,
  sortedCountries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  playerCustomFields: PropTypes.object,
  ckeditorPath: PropTypes.string,
  leagueId: PropTypes.string,
  dateFormat: PropTypes.string,
}

export default CustomFieldComponent
