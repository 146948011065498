import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgSelect } from 'SharedComponents'
import ReactDOMServer from 'react-dom/server'
import LabeledInput from './event_setup/labeled_input'
import { computeHour, computeMinutes } from './event_setup/time_helpers'
import InputForm from './event_setup/input_form'


class EventSetupPairings extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataChanged: false,
    }

    if(this.props.content.data.courses_tees.variable_settings_default_course == -1) {
      this.props.setCourseTee(Object.values(this.props.content.data.courses_tees.default_courses)[0]?.value || -1)
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content.data !== prevProps.content.data && this.props.content.dataChanged) || this.state.dataChanged) {
      this.props.save()
      this.setState({dataChanged: false})
    }
  }

  filterTeesByGender(tees, gender) {
    return tees.filter(tee => ['', null, gender].includes(tee.gender))
  }

  render() {
    const { title, data } = this.props.content
    const setter = (keys) => (value) => this.props.setData([ 'data' ].concat(keys), value)

    const coursesTees = data.courses_tees
    const teesForDefaultCourse = Object.values(
      'default_courses' in coursesTees &&
      coursesTees.default_courses[coursesTees.variable_settings_default_course]?.tees || {}
    )

    const maleTees = this.filterTeesByGender(teesForDefaultCourse, "Male")
    const femaleTees = this.filterTeesByGender(teesForDefaultCourse, "Female")

    const noCoursesLabel = (
      <label className='courses-not-found'>
        <div dangerouslySetInnerHTML={{
          __html: window.I18n.t(
          'pairings.set_course.set_courses_html',
          { href: ReactDOMServer.renderToString(<a href={coursesTees.courses_url} className='blue-normal'>{window.I18n.t('pairings.set_course.here')}</a>) }),
          }} />
      </label>
    )

    return (
      <div className='widget-content-default widget-event-setup-pairings'>
        <div className='title'>
          { title }
        </div>

        <div className='content flex-list-layout'>
          <ul>
            <li>
              <LabeledInput label={ data.pairing_group_size.label } childrenClass='wide' >
                <GlgSelect
                  items={ data.pairing_group_size.options }
                  value={ data.pairing_group_size.value }
                  onChange={ this.props.setPairingGroupSize } />
              </LabeledInput>
            </li>


            {
              data.pairing_group_size.value !== 1 && 'pairing_groups_count' in data ?
                <>
                  <li>
                    <LabeledInput label={ data.pairing_groups_count.label } childrenClass='narrow' >
                      <input
                        className='number-of-pairing-groups form-control'
                        value={ data.pairing_groups_count.value }
                        onChange={ e => setter([ 'pairing_groups_count', 'value' ])(e.target.value) }
                        onBlur={ e => setter([ 'pairing_groups_count', 'value' ])(parseInt(e.target.value) || 0) }
                      />
                    </LabeledInput>
                  </li>
                  
                </>
                :
                null
            }

            {
              'default_courses' in coursesTees ?
                <>
                  <>
                    <li>
                      <LabeledInput label={ coursesTees.label_default_course } childrenClass='wide' >
                        <GlgSelect
                          items={ Object.values(coursesTees.default_courses) }
                          placeholder="Select a course"
                          value={ coursesTees.variable_settings_default_course }
                          onChange={ this.props.setCourseTee } />
                      </LabeledInput>
                    </li>

                    <li>
                      <LabeledInput className='default-tee-by-gender' diffKey='no-teesheet-number-of-holes' label={ coursesTees.label_default_tee_by_gender } childrenClass='wider'>
                        <>
                          <div className='tee-by-gender'>
                            <label className='font-weight-normal'>M</label>
                            <GlgSelect
                              items={ maleTees }
                              value={ coursesTees.variable_settings_default_tee_m }
                              ignoreEmptyList={ false }
                              onChange={ setter([ 'courses_tees', 'variable_settings_default_tee_m' ]) }
                              usePortal={ teesForDefaultCourse.length > 5 }
                              menuShouldBlockScroll={ teesForDefaultCourse.length > 5 }
                              menuPlacement='bottom' />
                          </div>

                          <div className='tee-by-gender'>
                            <label className='font-weight-normal'>F</label>
                            <GlgSelect
                              items={ femaleTees }
                              value={ coursesTees.variable_settings_default_tee_f }
                              ignoreEmptyList={ false }
                              onChange={ setter([ 'courses_tees', 'variable_settings_default_tee_f' ]) }
                              usePortal={ teesForDefaultCourse.length > 5 }
                              menuShouldBlockScroll={ teesForDefaultCourse.length > 5 }
                              menuPlacement='bottom' />
                          </div>
                        </>
                      </LabeledInput>
                    </li>
                  </>
                  
                  {
                    !$.isEmptyObject(coursesTees.default_courses) && 
                      <li>
                        <LabeledInput diffKey='no-teesheet-number-of-holes' label={ coursesTees.label_holes } childrenClass='medium'>
                                <GlgSelect
                                  items={ Object.values(coursesTees.default_courses[coursesTees.variable_settings_default_course]?.holes || {}) }
                                  value={ coursesTees.variable_settings_hole_type }
                                  onChange={ this.props.setHole } />
                              </LabeledInput>
                      </li>
                  }

                  {data.pairing_group_size.value === 1 &&
                  <li>
                    <div className='checkbox-options'>
                      <b>{ data.course_selection.label }</b>
                      <div className='checkbox-container vertical'>

                        <label className='icheck-label' htmlFor='allow_players_from_mobile'>
                          <div className={`icheckbox_square-orange icheck-item ${data.course_selection.allow_players_from_mobile.selected ? 'checked' : ''}`}>
                            <input
                              id='allow_players_from_mobile'
                              className='i-checks icheck-input'
                              type='checkbox'
                              value={data.course_selection.allow_players_from_mobile.selected}
                              checked={data.course_selection.allow_players_from_mobile.selected}
                              onChange={event => setter([ 'course_selection', 'allow_players_from_mobile', 'selected' ])(event.target.checked)}
                            />
                          </div>
                          <span>{data.course_selection.allow_players_from_mobile.label}</span>
                        </label>

                        {
                          data.course_selection.allow_players_from_mobile.selected && data.course_selection.attester_required &&
                            <label className='icheck-label' htmlFor='attester_required'>
                              <div className={`icheckbox_square-orange icheck-item ${data.course_selection.attester_required.selected ? 'checked' : ''}`}>
                                <input
                                  id='attester_required'
                                  className='i-checks icheck-input'
                                  type='checkbox'
                                  value={data.course_selection.attester_required.selected}
                                  checked={data.course_selection.attester_required.selected}
                                  onChange={event => setter([ 'course_selection', 'attester_required', 'selected' ])(event.target.checked)}
                                />
                              </div>
                              <span>{data.course_selection.attester_required.label}</span>
                            </label>
                        }

                      </div>
                    </div>
                  </li>}
                </>

              : 
                <li> { noCoursesLabel } </li>
            }
            {
              'default_attendance_status' in data &&
                <li>
                  <LabeledInput label={ data.default_attendance_status.label } childrenClass='medium' >
                    <GlgSelect
                      items={ data.default_attendance_status.options }
                      value={ data.default_attendance_status.value }
                      onChange={ setter([ 'default_attendance_status', 'value' ]) }
                      usePortal={ true }
                      menuShouldBlockScroll={ true }
                    />
                  </LabeledInput>
                </li>
            }

            {
              'tee_times' in data &&
                <>
                  <li>
                    <LabeledInput label={ data.tee_times.first_tee_time.label } childrenClass='medium first_tee_time_container' >
                      <InputForm
                        className='form-control'
                        value={ data.tee_times.first_tee_time.hour }
                        onEditCompleted={(value) => {
                          const [ hour, amPm ] = computeHour(value)
                          setter([ 'tee_times', 'first_tee_time', 'hour' ])(hour)
                          setter([ 'tee_times', 'first_tee_time', 'am_pm' ])(amPm)
                        }}
                      />
                      {':'}
                      <InputForm
                        className='form-control'
                        value={ data.tee_times.first_tee_time.min }
                        onEditCompleted={(value) => {
                          setter([ 'tee_times', 'first_tee_time', 'min' ])(computeMinutes(value))
                          this.setState({dataChanged: true})
                        }}
                      />
                      <a 
                        className='am_pm'
                        onClick={ () => setter([ 'tee_times', 'first_tee_time', 'am_pm' ])(data.tee_times.first_tee_time.am_pm === 'AM' ? 'PM' : 'AM' )}>
                        {data.tee_times.first_tee_time.am_pm}
                      </a>
                    </LabeledInput>
                  </li>

                  <li>
                    <LabeledInput label={ data.tee_times.last_tee_time.label } childrenClass='medium last_tee_time_container' >
                      <InputForm
                        className='form-control'
                        value={ data.tee_times.last_tee_time.hour }
                        onEditCompleted={(value) => {
                          const [ hour, amPm ] = computeHour(value)
                          setter([ 'tee_times', 'last_tee_time', 'hour' ])(hour)
                          setter([ 'tee_times', 'last_tee_time', 'am_pm' ])(amPm)
                        }}
                      />
                      {':'}
                      <InputForm
                        className='form-control'
                        value={ data.tee_times.last_tee_time.min }
                        onEditCompleted={(value) => {
                          setter([ 'tee_times', 'last_tee_time', 'min' ])(computeMinutes(value))
                          this.setState({dataChanged: true})
                        }}
                      />
                      <a
                        className='am_pm'
                        onClick={ () => setter([ 'tee_times', 'last_tee_time', 'am_pm' ])(data.tee_times.last_tee_time.am_pm === 'AM' ? 'PM' : 'AM' )}>
                        {data.tee_times.last_tee_time.am_pm}
                      </a>
                    </LabeledInput>
                  </li>

                  <li>
                    <LabeledInput label={ data.tee_times.interval.label } childrenClass='narrow' >
                      <InputForm
                        className='tee-times-interval form-control'
                        type='number'
                        value={ data.tee_times.interval.value }
                        onEditCompleted={(value) => {
                          setter([ 'tee_times', 'interval', 'value' ])(Math.max(parseInt(value) || 0, 0))
                          this.setState({dataChanged: true})
                        }}
                      />
                    </LabeledInput>
                  </li>

                  <li>
                    <LabeledInput label={ data.tee_times.starters_time.label } childrenClass='narrow' >
                      <InputForm
                        className='tee-times-interval form-control'
                        type='number'
                        value={ data.tee_times.starters_time.value }
                        onEditCompleted={(value) => {
                          setter([ 'tee_times', 'starters_time', 'value' ])(Math.max(parseInt(value) || 0, 0))
                          this.setState({dataChanged: true})
                        }}
                      />
                    </LabeledInput>
                  </li>
                </>
            }

            {
              'hide_players_on_tee_times' in data &&
                <li>
                    <LabeledInput label={ data.hide_players_on_tee_times.label } childrenClass='narrow' >
                      <div
                        className={`hide_players_on_tee_times icheckbox_square-orange icheck-item ${data.hide_players_on_tee_times.selected ? 'checked' : ''}`}
                        onClick={ () => setter([ 'hide_players_on_tee_times', 'selected' ])(! data.hide_players_on_tee_times.selected)}
                      />
                    </LabeledInput>
                </li>
            }

            {
              'ott_ggid_read_only' in data &&
                <li>
                    <LabeledInput label={ data.ott_ggid_read_only.label } childrenClass='narrow' >
                      <div
                        className={`ott_ggid_read_only icheckbox_square-orange icheck-item ${data.ott_ggid_read_only.selected ? 'checked' : ''}`}
                        onClick={ () => setter([ 'ott_ggid_read_only', 'selected' ])(! data.ott_ggid_read_only.selected)}
                      />
                    </LabeledInput>
                </li>
            }
          </ul>
        </div>

      </div>
    )
  }
}

EventSetupPairings.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  setHole: PropTypes.func,
  setCourseTee: PropTypes.func,
  setPairingGroupSize: PropTypes.func,
  save: PropTypes.func,
}

EventSetupPairings.defaultProps = {
  content: {
    title: 'PAIRINGS',
    data: {},
  },
}

export default EventSetupPairings

