import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgLoadingSpinner, GlgPopupForm } from 'SharedComponents'
import CustomFieldComponent from './custom_field_component'
import { Col } from 'react-flexbox-grid'
import { Scrollbar } from 'react-scrollbars-custom'

export class EditCustomFieldsComponent extends Component {
  constructor(props) {
    super(props)
    this.onClose = this.onClose.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSave = this.onSave.bind(this)
  }
  
  onClose(){
    this.props.closeEditCustomFieldsPopup()
    this.props.openAddGolfersManuallyPopup()
    window.onbeforeunload = null
  }
  
  onChange(id, category, name, value, answer, answerId){
    if (category === 'single_string' || category === 'default' || category === 'textarea') {
      if (this.customFieldOnChangeTimeout) {
        clearTimeout(this.customFieldOnChangeTimeout)
      }
      this.customFieldOnChangeTimeout = setTimeout(() => {
        this.props.changeCurrentCustomFields(id, category, name, value, answer, answerId)
      }, 500)
    } else {
      this.props.changeCurrentCustomFields(id, category, name, value, answer, answerId)
    }
  }
  
  onSave(){
    this.props.saveNewMemberField('customFields', this.props.customFields, this.props.playerIndex)
    this.props.closeEditCustomFieldsPopup()
    this.props.openAddGolfersManuallyPopup()
    window.onbeforeunload = null
  }

  render() {
    const member = this.props.newMembers[this.props.playerIndex]
    const popUpTitle = <div key="popup_title" className="title">{ !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.edit_custom_fields_component.edit_for', { name: member.lastName}) }</div>
    return <GlgPopup title={() => popUpTitle} modalClass="popup_new_member_custom_fields new_mass_member_custom_fields modal-lg use-custom-scrollbars" show={ this.props.show } onClose={ this.onClose } manualClose={ true } showCloseButton={ false } showSaveButton={ false } customButtons={[
                  { class: 'gdpr_btn btsp_orange_button save_button pull-right btn ' + (this.props.disableSaveFields ? 'disabled' : ''), text: !window.I18n ? '' : window.I18n.t('members_page.components.add_golfers_manually.edit_custom_fields_component.save_fields'), onClick: this.onSave },
                  { class: 'cancel_roster btn button btsp_gray_button pull-right', text: !window.I18n ? '' : window.I18n.t('buttons.cancel').charAt(0)
                    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.cancel').slice(1)), onClick: this.onClose },
                ]}><Scrollbar style={{height: 'auto'}} wrapperProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', maxHeight: '50vh'}} />
            },
          }} scrollerProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <div {...restProps} ref={elementRef} style={{...restProps.style, position: 'relative', maxHeight: '50vh'}} />
            },
          }} trackYProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props
              return <span {...restProps} ref={elementRef} style={{...restProps.style, height: 'calc(100%)'}} />
            },
          }} disableTracksWidthCompensation={true}><GlgPopupForm><GlgLoadingSpinner show={ this.props.isLoading } height={ 50 } width={ 50 } className="loading-spinner"></GlgLoadingSpinner>{ this.props.membersMetadata.map((metadataCouple, index) => <Col xs={12} key={`custom_field_${index}`}><CustomFieldComponent field={metadataCouple[0][1]} affiliationAnswers={this.props.affiliationAnswers} sortedCountries={this.props.sortedCountries} onChange={this.onChange} playerCustomFields={this.props.customFields} ckeditorPath={this.props.ckeditorPath} leagueId={this.props.leagueId} dateFormat={this.props.dateFormat}></CustomFieldComponent>{ metadataCouple[1] && <CustomFieldComponent field={metadataCouple[1][1]} affiliationAnswers={this.props.affiliationAnswers} sortedCountries={this.props.sortedCountries} onChange={this.onChange} playerCustomFields={this.props.customFields} leagueId={this.props.leagueId} dateFormat={this.props.dateFormat}></CustomFieldComponent>}</Col>)}</GlgPopupForm></Scrollbar></GlgPopup>
  }
}

EditCustomFieldsComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  closeEditCustomFieldsPopup: PropTypes.func.isRequired,
  openAddGolfersManuallyPopup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  membersMetadata: PropTypes.array.isRequired,
  affiliationAnswers: PropTypes.array.isRequired,
  sortedCountries: PropTypes.array.isRequired,
  playerIndex: PropTypes.number.isRequired,
  saveNewMemberField: PropTypes.func.isRequired,
  changeCurrentCustomFields: PropTypes.func.isRequired,
  newMembers: PropTypes.array.isRequired,
  disableSaveFields: PropTypes.bool.isRequired,
  customFields: PropTypes.object.isRequired,
  ckeditorPath: PropTypes.string.isRequired,
  elementRef: PropTypes.any,
  leagueId: PropTypes.string,
  dateFormat: PropTypes.string,
}

export default EditCustomFieldsComponent
