import { ActionTypes } from '../actions'
import arrayMove from 'array-move'
import { resetHash } from '../helpers'
import { getColumnName } from '../helpers'

const {
  FETCH_FIELDS_DATA_REQUEST,
  FETCH_FIELDS_DATA_RECEIVED,
  OPEN_FIELDS_SIDEBAR,
  CLOSE_FIELDS_SIDEBAR,
  OPEN_HANDLE_FORMAT_SIDEBAR,
  CLOSE_HANDLE_FORMAT_SIDEBAR,
  SET_MEMBER_COLUMNS_REQUEST,
  SET_MEMBER_COLUMNS_RECEIVED,
  CHECK_MEMBER_FIELD,
  UNCHECK_MEMBER_FIELD,
  SWAP_FIELDS,
  SET_SIDEBAR_ICONS,
  OPEN_UPLOAD_SPREADSHEET_SIDEBAR,
  CLOSE_UPLOAD_SPREADSHEET_SIDEBAR,
  TOGGLE_TERMS_AND_PRIVACY,
  UPLOAD_ROSTER_SPREADSHEET_REQUEST,
  REFORMAT_HANDLES_REQUEST,
  REFORMAT_HANDLES_RECEIVED,
} = ActionTypes

const initialState = {
  isLoading: false,
  fetchFieldsDataUrl: '',
  leftFields: [],
  rightFields: [],
  showHandleTooltip: false,
  showLastNameTooltip: false,
  positionLeft: 0,
  isOpen: {
    fields: false,
    handleFormat: false,
    uploadSpreadsheet: false,
  },
  isMemberColumnsLoading: false,
  membersHandle: '',
  imageUrls: {},
  sidebarIconsSet: false,
  termsAndPrivacyChecked: {
    uploadRoster: false,
  },
  isUploadRosterLoading: false,
  isReformatNamesLoading: false,
}

const columnNameCompareMethod = (x1, x2, action) => {
  const col1 = getColumnName(x1, action.indexType, action.prettyHandicapMethod, action.membershipPrograms).name.toLowerCase()
  const col2 = getColumnName(x2, action.indexType, action.prettyHandicapMethod, action.membershipPrograms).name.toLowerCase()
  return col1 < col2 ? -1 : 1
}

const leftSidebarFields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIELDS_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_FIELDS_DATA_RECEIVED:
      return {
        ...state,
        isLoading: false,
        ...action.data,
        rightFields: action.data.rightFields.sort((x1, x2) => columnNameCompareMethod(x1, x2, action)),
      }
    case SET_MEMBER_COLUMNS_REQUEST:
      return {
        ...state,
        isMemberColumnsLoading: true,
      }
    case SET_MEMBER_COLUMNS_RECEIVED:
      return {
        ...state,
        isMemberColumnsLoading: false,
        isOpen: {
          ...resetHash(state.isOpen),
        },
      }
    case OPEN_FIELDS_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
          fields: true,
        },
      }
    case CLOSE_FIELDS_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
        },
      }
    case OPEN_HANDLE_FORMAT_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
          handleFormat: true,
        },
      }
    case CLOSE_HANDLE_FORMAT_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
        },
      }
    case CHECK_MEMBER_FIELD:
      if (action.field === 'full_name') {
        //If we check full_name and last_name is unchecked (but still in leftFields)
        if (state.leftFields.indexOf('last_name') > -1 && state.showLastNameTooltip) {
          return {
            ...state,
            showLastNameTooltip: false,
            leftFields: [ ...state.leftFields.filter(x => x !== 'last_name'), 'full_name' ],
            rightFields: [ ...state.rightFields.filter(x => x !== 'full_name'), 'last_name' ].sort((x1, x2) => columnNameCompareMethod(x1, x2, action)),
          }
        } else if (!(state.leftFields.indexOf('last_name') > -1) && state.showHandleTooltip) {
          return {
            ...state,
            showHandleTooltip: false,
          }
        }
      }
      if (action.field === 'last_name') {
        if (state.leftFields.indexOf('full_name') > -1 && state.showHandleTooltip) {
          return {
            ...state,
            showHandleTooltip: false,
            leftFields: [ ...state.leftFields.filter(x => x !== 'full_name'), 'last_name' ],
            rightFields: [ ...state.rightFields.filter(x => x !== 'last_name'), 'full_name' ].sort((x1, x2) => columnNameCompareMethod(x1, x2, action)),
          }
        } else if (!(state.leftFields.indexOf('full_name') > -1) && state.showLastNameTooltip) {
          return {
            ...state,
            showLastNameTooltip: false,
          }
        }
      }
      return {
        ...state,
        leftFields: [ ...state.leftFields, action.field ],
        rightFields: state.rightFields.filter(x => x !== action.field),
      }
    case UNCHECK_MEMBER_FIELD: {
      if (action.field === 'full_name' && !(state.leftFields.indexOf('last_name') > -1)) {
        return {
          ...state,
          showHandleTooltip: true,
        }
      }
      if (action.field === 'last_name' && !(state.leftFields.indexOf('full_name') > -1)) {
        return {
          ...state,
          showLastNameTooltip: true,
        }
      }
      return {
        ...state,
        leftFields: state.leftFields.filter(x => x !== action.field),
        rightFields: [ ...state.rightFields, action.field ].sort((x1, x2) => columnNameCompareMethod(x1, x2, action)),
      }
    }
    case SWAP_FIELDS: {
      const tmpLeftFields = arrayMove(state.leftFields, action.sourceIndex, action.destinationIndex)
      return {
        ...state,
        leftFields: tmpLeftFields,
      }
    }
    case OPEN_UPLOAD_SPREADSHEET_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
          uploadSpreadsheet: true,
        },
      }
    case CLOSE_UPLOAD_SPREADSHEET_SIDEBAR:
      return {
        ...state,
        isOpen: {
          ...resetHash(state.isOpen),
        },
      }
    case SET_SIDEBAR_ICONS: {
      const imageUrls = {
        fields: require('bootstrap_theme/members/fields.png'),
        name_format: require('bootstrap_theme/members/display_name_format.png'),
        import_golfers: require('bootstrap_theme/members/import_golfers.png'),
        add_golfers: require('bootstrap_theme/members/add_golfers_manually.png'),
        upload_roster: require('bootstrap_theme/members/upload_spreadsheet.png'),
        download_spreadsheet: require('bootstrap_theme/members/download_spreadsheet.png'),
        print_roster: require('bootstrap_theme/members/print_roster.png'),
        upload_photo: require('bootstrap_theme/members/photo.png'),
        merge: require('bootstrap_theme/members/merge.svg'),
      }
      return {
        ...state,
        imageUrls: imageUrls,
        sidebarIconsSet: true,
      }
    }
    case TOGGLE_TERMS_AND_PRIVACY:
      return {
        ...state,
        termsAndPrivacyChecked: {
          ...state.termsAndPrivacyChecked,
          [action.toggleType]: action.checked,
        },
      }
    case UPLOAD_ROSTER_SPREADSHEET_REQUEST:
      return {
        ...state,
        isUploadRosterLoading: true,
      }
    case REFORMAT_HANDLES_REQUEST:
      return {
        ...state,
        isReformatNamesLoading: true,
      }
    case REFORMAT_HANDLES_RECEIVED:
      return {
        ...state,
        isReformatNamesLoading: false,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default leftSidebarFields
