import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import DatePicker from 'react-datepicker'
import { format, parse } from 'date-fns/esm'
import CalendarInputComponent from './calendar_input_component'

export class DateComponent extends Component {
  constructor(props){
    super(props)
  }
  
  render(){
    return <Col xs={12} md={8}><DatePicker name="round_date" selected={ this.props.answer ? parse(this.props.answer, 'MM/dd/yyyy', new Date()) : new Date()} locale={window.I18n.locale} showMonthDropdown={true} showYearDropdown={true} customInput={<CalendarInputComponent />} maxDate={new Date()} dateFormat={this.props.dateFormat.replace(/m/g, 'M')} onChange={(date) => {
            // Convert the selected date to 'MM/dd/yyyy' format before saving it
            const formattedDate = format(date, 'MM/dd/yyyy')
            this.props.onChange(this.props.field.id, this.props.field.category, this.props.field.name, formattedDate)
          }} dropdownMode={'select'} popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport',
            },
          }}></DatePicker></Col>
  }
}

DateComponent.propTypes = {
  field: PropTypes.object.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
}

export default DateComponent
