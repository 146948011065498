import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'
import {
  GlgTooltipIcon,
} from 'SharedComponents'
import DatePicker, {registerLocale} from 'react-datepicker'
import CalendarInput from './calendar_input'
import { subMonths, addMonths } from 'date-fns/esm'
import fr from 'date-fns/locale/fr'
import ja from 'date-fns/locale/ja'

registerLocale('fr', fr)
registerLocale('jp', ja)

class StartDateFilter extends Component {
  constructor(props) {
    super(props)
    this.onChange = props.onChange.bind(this, 'startDate')
  }

  getDateFormat() {
    if (this.props.customerDateFormat === 'YMD')
      return 'yyyy/MM/dd'
    else if (this.props.customerDateFormat === 'DMY')
      return 'dd/MM/yyyy'
    else
      return 'MM/dd/yyyy'
  }

  render() {

    return <FormGroup><Row><Col xs={true}><h4><span className="label filter-label"><div className="add-margin-top-5"><span></span>{ !window.I18n ? '' : window.I18n.t('customer_directories.filters.start_date_title') }<span className="pull-right"><GlgTooltipIcon tooltip={!window.I18n ? '' : window.I18n.t('customer_directories.filters.start_end_date_tooltip')}><i className="gga-info-circle"></i></GlgTooltipIcon></span></div></span></h4></Col></Row><Row><Col xs={true}><div className="height-38"><DatePicker name="start_date" selected={this.props.value} locale={window.I18n.locale} peekNextMonth={true} showMonthDropdown={true} showYearDropdown={true} customInput={<CalendarInput />} minDate={subMonths(new Date(), 120)} maxDate={addMonths(new Date(), 120)} onChange={this.onChange} dateFormat={this.getDateFormat()} dropdownMode={'select'} popperModifiers={{
                           preventOverflow: {
                             enabled: true,
                             escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                             boundariesElement: 'viewport',
                           },
                         }}></DatePicker></div></Col></Row></FormGroup>
  }
}

StartDateFilter.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  customerDateFormat: PropTypes.string,
}


export default StartDateFilter
