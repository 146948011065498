import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../reducers'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import DirectoriesContainer from '../containers/directories_container'

const DirectoriesRoot = ({
  fetchInitialDataURL, authenticityToken, viewType, toursEnabled, selectedTour, cartMerchantAccountId, saveFiltersURL,
  cartMerchantAccountType, currentLocation, directoryId, seasonId, customerId, metricUnits, customerDateFormat,
}) => {

  const state = {
    misc: {
      fetchInitialDataURL,
      toursEnabled,
      statusFilterVisible: {
        myRegistrations: false,
        open: false,
        pastEvents: false,
        upcomingEvents: false,
        lotteryEvents: false,
      },
      customerDateFormat,
      metricUnits,
      selectedTour,
      cartMerchantAccountId,
      cartMerchantAccountType,
      saveFiltersURL,
      directoryId,
      customerId,
      seasonId,
    },
    filters: {
      viewType,
      advancedFiltersOn: true,
      currentLocation,
    },
  }

  const store = configureStore(rootReducer, state)

  const globalData = {
    formAuthenticityToken: authenticityToken,
  }

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.glg.dispatch = store.dispatch

  return <GlobalProvider data={ globalData } store={ store }><DirectoriesContainer></DirectoriesContainer></GlobalProvider>
}

DirectoriesRoot.propTypes = {
  fetchInitialDataURL: PropTypes.string,
  authenticityToken: PropTypes.string,
  viewType: PropTypes.string,
  customerName: PropTypes.string,
  directoryName: PropTypes.string,
  directoryDescription: PropTypes.string,
  toursEnabled: PropTypes.bool,
  selectedTour: PropTypes.string,
  cartMerchantAccountId: PropTypes.string,
  cartMerchantAccountType: PropTypes.string,
  saveFiltersURL: PropTypes.string,
  currentLocation: PropTypes.string,
  directoryId: PropTypes.string,
  seasonId: PropTypes.string,
  customerId: PropTypes.string,
  metricUnits: PropTypes.string,
  customerDateFormat: PropTypes.string,
}
export default DirectoriesRoot
