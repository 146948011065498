import { connect } from 'react-redux'

import {
  changeFilter,
} from '../actions'

import {
  getToursOptions,
  checkShowTours,
} from '../selectors'

import DirectoriesFiltersComponent from '../components/directories_filters_component'

const mapStateToProps = state => {
  const toursOptions = getToursOptions(state, { addAll: true, addEmptyOption: true })
  const showTours = checkShowTours(state, { checkLeagues: true })

  return {
    viewType: state.filters.viewType,
    advancedFiltersOn: state.filters.advancedFiltersOn,
    dataIsLoading: !!state.misc.dataIsLoading,
    allDataIsLoading: !!state.misc.allDataIsLoading,
    showTours,
    toursOptions,
    selectedEventStatus: state.filters.status,
    selectedTour: state.filters.tour,
    leagueName: state.filters.leagueName,
    startDate: Date.parse(state.filters.startDate),
    endDate: Date.parse(state.filters.endDate),
    statusFilterVisible: {
      myRegistrations: state.misc.statusFilterVisible.myRegistrations,
      open: state.misc.statusFilterVisible.open,
      pastEvents: state.misc.statusFilterVisible.pastEvents,
      upcomingEvents: state.misc.statusFilterVisible.upcomingEvents,
      lotteryEvents: state.misc.statusFilterVisible.lotteryEvents,
    },
    location: state.filters.location,
    MRMember: state.misc.MRMember,
    showLocationFilter: state.misc.showLocationFilter,
    currentLocation: state.filters.currentLocation,
    customerDateFormat: state.misc.customerDateFormat,
    metricUnits: state.misc.metricUnits,
    shouldShowLotteryFilter: state.misc.shouldShowLotteryFilter,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFilter: (filter, value) => dispatch(changeFilter(filter, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectoriesFiltersComponent)
