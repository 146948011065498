import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'

import MembersPageContainer from './members_page_container'

const MembersPageRoot = ({
  fetchMembersDataUrl, isCustomerRosterLeague, canManageLeague, isDailySync, customerIsGolfAdvisor, newLeagueImportPath, isTourManager,
  downloadLeagueSpreadsheetfilesPath, schedulePrintLeagueMembersPath, bulkUploadPhotosPath, showMemberOptions, fetchFieldsDataUrl, setMemberColumnsUrl, setHandleFormatUrl,
  leagueId, canOpenMemberModal, searchParams, membersHandle, indexType, hasAutomatedHandicaps, hasDivisions, prettyHandicapMethod, fetchLeagueMetadataUrl,
  saveNewMembersUrl, getMembersCountUrl, loadFilterOptionsUrl, branding, termsAndConditionsUrl, privacyPolicyUrl, canUseGolfChannelFlightRanking,
  modalChangeHandicapIndex, loadPropagationLeaguesUrl, propagateLeaguesUrl, resetRosterLeagueUrl, getResetRosterAnswerLeagueUrl, cannotDeleteRoster,
  filepickerOptions, canUseMasterRosterRegistration, uploadRosterSpreadsheetUrl, customerSoftDelete, hsInfoLink, openOption, isGcatCustomer,
  memberId, ckeditorPath, newUserSessionUrl, leagueProduct, resetPasswordUrl, isPgaCustomer, isJapanCustomer, isAusCustomer, hasMrSyncEnabled, editEmailTemplateRegisterMemberWithPasswordUrl,
  editEmailTemplateRegisterMemberWithoutPasswordUrl, customHandlesName, customFieldsNames, hasOverrideFirstName, hasOverrideWithKanji,
  matchingRulesPath, matchingRulesEnabled,
}) => {
  const state = {
    membersData: {
      fetchMembersDataUrl,
      getMembersCountUrl,
      loadFilterOptionsUrl,
      searchParams,
      canUseMasterRosterRegistration,
    },
    leftSidebarFields: {
      fetchFieldsDataUrl,
      setMemberColumnsUrl,
      setHandleFormatUrl,
      cannotDeleteRoster,
      membersHandle,
      filepickerOptions,
      uploadRosterSpreadsheetUrl,
    },
    cellMeasurerCache: {
    },
    addNewMembers: {
      fetchLeagueMetadataUrl,
      saveNewMembersUrl,
    },
    leaguesPropagation: {
      loadPropagationLeaguesUrl,
      propagateLeaguesUrl,
    },
    deleteRosterConfirmation: {
      getResetRosterAnswerLeagueUrl,
      resetRosterLeagueUrl,
    },
    misc: {
      isCustomerRosterLeague,
      canManageLeague,
      isDailySync,
      customerIsGolfAdvisor,
      newLeagueImportPath,
      isTourManager,
      downloadLeagueSpreadsheetfilesPath,
      schedulePrintLeagueMembersPath,
      bulkUploadPhotosPath,
      matchingRulesPath,
      matchingRulesEnabled,
      showMemberOptions,
      leagueId,
      canOpenMemberModal,
      indexType,
      hasAutomatedHandicaps,
      hasDivisions,
      prettyHandicapMethod,
      branding,
      termsAndConditionsUrl,
      privacyPolicyUrl,
      canUseGolfChannelFlightRanking,
      modalChangeHandicapIndex,
      customerSoftDelete,
      hsInfoLink,
      openOption,
      isGcatCustomer,
      memberId,
      ckeditorPath,
      newUserSessionUrl,
      leagueProduct,
      resetPasswordUrl,
      isPgaCustomer,
      isJapanCustomer,
      isAusCustomer,
      hasMrSyncEnabled,
      customHandlesName,
      customFieldsNames,
      hasOverrideFirstName,
      hasOverrideWithKanji,
      editEmailTemplateRegisterMemberWithPasswordUrl,
      editEmailTemplateRegisterMemberWithoutPasswordUrl,
    },
  }
  
  const store = configureStore(rootReducer, state)
  window.glg.member.dispatch = store.dispatch

  return <GlobalProvider store={ store }><MembersPageContainer></MembersPageContainer></GlobalProvider>
}

MembersPageRoot.propTypes = {
  fetchMembersDataUrl: PropTypes.string.isRequired,
  isCustomerRosterLeague: PropTypes.bool.isRequired,
  canManageLeague: PropTypes.bool.isRequired,
  isDailySync: PropTypes.bool.isRequired,
  customerIsGolfAdvisor: PropTypes.bool.isRequired,
  isTourManager: PropTypes.bool.isRequired,
  newLeagueImportPath: PropTypes.string.isRequired,
  downloadLeagueSpreadsheetfilesPath: PropTypes.string.isRequired,
  schedulePrintLeagueMembersPath: PropTypes.string.isRequired,
  bulkUploadPhotosPath: PropTypes.string.isRequired,
  matchingRulesPath: PropTypes.string.isRequired,
  matchingRulesEnabled: PropTypes.bool.isRequired,
  showMemberOptions: PropTypes.bool.isRequired,
  fetchFieldsDataUrl: PropTypes.string.isRequired,
  setMemberColumnsUrl: PropTypes.string.isRequired,
  setHandleFormatUrl: PropTypes.string.isRequired,
  leagueId: PropTypes.string.isRequired,
  canOpenMemberModal: PropTypes.bool.isRequired,
  searchParams: PropTypes.object.isRequired,
  membersHandle: PropTypes.string.isRequired,
  indexType: PropTypes.string.isRequired,
  hasAutomatedHandicaps: PropTypes.bool.isRequired,
  hasDivisions: PropTypes.bool.isRequired,
  prettyHandicapMethod: PropTypes.string,
  fetchLeagueMetadataUrl: PropTypes.string.isRequired,
  saveNewMembersUrl: PropTypes.string.isRequired,
  getMembersCountUrl: PropTypes.string.isRequired,
  loadFilterOptionsUrl: PropTypes.string.isRequired,
  branding: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  loadPropagationLeaguesUrl: PropTypes.string.isRequired,
  resetRosterLeagueUrl: PropTypes.string.isRequired,
  getResetRosterAnswerLeagueUrl: PropTypes.string.isRequired,
  propagateLeaguesUrl: PropTypes.string.isRequired,
  uploadRosterSpreadsheetUrl: PropTypes.string.isRequired,
  canUseGolfChannelFlightRanking: PropTypes.bool.isRequired,
  modalChangeHandicapIndex: PropTypes.bool.isRequired,
  cannotDeleteRoster: PropTypes.bool.isRequired,
  canUseMasterRosterRegistration: PropTypes.bool.isRequired,
  customerSoftDelete: PropTypes.bool.isRequired,
  filepickerOptions: PropTypes.object.isRequired,
  hsInfoLink: PropTypes.string,
  openOption: PropTypes.string,
  isGcatCustomer: PropTypes.bool.isRequired,
  isPgaCustomer: PropTypes.bool.isRequired,
  isJapanCustomer: PropTypes.bool.isRequired,
  isAusCustomer: PropTypes.bool,
  hasMrSyncEnabled: PropTypes.bool.isRequired,
  memberId: PropTypes.string,
  ckeditorPath: PropTypes.string.isRequired,
  newUserSessionUrl: PropTypes.string.isRequired,
  leagueProduct: PropTypes.string.isRequired,
  resetPasswordUrl: PropTypes.string.isRequired,
  customHandlesName: PropTypes.string.isRequired,
  customFieldsNames: PropTypes.array.isRequired,
  hasOverrideFirstName: PropTypes.bool.isRequired,
  hasOverrideWithKanji: PropTypes.bool.isRequired,
  editEmailTemplateRegisterMemberWithPasswordUrl: PropTypes.string.isRequired,
  editEmailTemplateRegisterMemberWithoutPasswordUrl: PropTypes.string.isRequired,
}

export default MembersPageRoot
