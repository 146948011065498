import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RadioGroup from './event_setup/radio_group'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import CheckboxOptions from './event_setup/checkbox_options'
import Checkbox from './event_setup/checkbox'
import LabeledInput from './event_setup/labeled_input'
import CalendarCustomInput from './event_setup/calendar_custom_input'
import { GlgTooltipIcon, GlgSelect } from 'SharedComponents'

class EventSetupRegistration extends Component {
  constructor(props){
    super(props)
  }


  render() {
    const { registration, players, dataChanged, saveInProgress, links } = this.props.content
    const internationalize = (key) => !window.I18n ? '' : window.I18n.t(key)

    const setData = (keys) => (value) => this.props.setData((keys), value)
    const buildSetter = (prefixKeys, setData) => (keys) => (value) => setData(prefixKeys.concat(keys))(value)

    const registrationSetter = buildSetter([ 'registration', 'data' ], setData)
    const playersSetter = buildSetter([ 'players', 'data' ], setData)

    // registration stuff
    const registrationEnabled = registration.data.registration_enablement.options.enable_registration.selected
    const registrationWithCreditCardEnabled = registration.data.registration_enablement.options.registration_with_credit_card?.selected
    const includeListOfParticipants = registration.data.show_list_of_participants.options.include_list_of_participants.selected
    const youthLeague = registration.data.youth_league

    const registrationSetupOptions = registration.data.registration_setup.options
    const registrationSetupOptionsSetter = buildSetter([ 'registration_setup', 'options' ], registrationSetter)

    const playerLimit = registration.data.player_limit
    const registrationDates = registration.data.registration_dates
    const dateFormat = registrationDates.date_format

    const registrationUpdateMin = (newVal, type, minVal = 0) => {
      if (newVal.length === 0) {
        registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'min' ])('')
        return
      }
      const min = Math.min(registration.data.registration_setup.min_max_members_guests.max_permissible_registrations, Math.max(minVal, parseInt(newVal) || minVal))
      const max = Math.max(min, registration.data.registration_setup.min_max_members_guests[type].max)

      registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'min' ])(min.toString())
      registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'max' ])(max.toString())
    }
    const registrationUpdateMax = (newVal, type, minVal = 0) => {
      if (newVal.length === 0) {
        registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'max' ])('')
        return
      }
      const max = Math.min(registration.data.registration_setup.min_max_members_guests.max_permissible_registrations, Math.max(minVal, parseInt(newVal) || minVal))
      const min = Math.min(max, parseInt(registration.data.registration_setup.min_max_members_guests[type].min) || 1)

      registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'min' ])(min.toString())
      registrationSetter([ 'registration_setup', 'min_max_members_guests', type, 'max' ])(max.toString())
    }

    // players stuff
    const eligibilityOptions = players && players.data.member_eligibility_criteria.options
    const eligibilityOptionsSetter = players && buildSetter([ 'member_eligibility_criteria', 'options' ], playersSetter)

    // error conditions
    const registrationOpenBeforeClose = registrationDates.open.value < registrationDates.close.value || !registrationDates.close.value
    const atLeastOne = youthLeague || (registration.data.registration_setup.min_max_members_guests?.members?.min >= 1 ||
                       registration.data.registration_setup.min_max_members_guests?.guests?.min >= 1)

    const saveDisabled = !(registrationOpenBeforeClose && (registrationWithCreditCardEnabled || atLeastOne))

    const getDateFormat = format => {
      if (format === 'YMD')
        return 'yyyy/MM/dd h:mm aa'
      else if (format === 'DMY')
        return 'dd/MM/yyyy h:mm aa'
      else
        return 'MM/dd/yyyy h:mm aa'
    }

    return (
      <div className='widget-content-default widget-event-setup-registration'>
        <div className='section registration'>
          <div className='title'>
            { registration.title }

            {
              dataChanged &&
                <a onClick={saveInProgress ? undefined : this.props.save} className={`btn btn-primary widget-white-title-button ${ saveDisabled ? 'disabled' : ''}`}>
                  { saveInProgress ? internationalize('dashboard_plus.widgets.saving') : links.save.name }
                </a>
            }
          </div>

          <div className='content flex-list-layout'>
            <ul>
              <li>
                <CheckboxOptions
                  {...registration.data['registration_enablement']}
                  setOption={buildSetter([ 'registration_enablement' ], registrationSetter)} />
              </li>

              <li className='player_limit_container'>
                <label>{ playerLimit.label }</label>
                <input
                  className='player-limit form-control'
                  value={ playerLimit.value }
                  onChange={ e => registrationSetter([ 'player_limit', 'value' ])(e.target.value) }
                  onBlur={ e => {
                    const val = parseInt(e.target.value)
                    registrationSetter([ 'player_limit', 'value' ])(isNaN(val) ? '' : val)
                  }}
                />
              </li>

              {
                registrationEnabled && (
                  <li>
                    <CheckboxOptions
                      {...registration.data['show_list_of_participants']}
                      setOption={buildSetter([ 'show_list_of_participants' ], registrationSetter)} />
                  </li>
                )
              }

              {
                registrationEnabled && includeListOfParticipants && (
                  <li>
                    <CheckboxOptions
                      {...registration.data['include_following_list']}
                      setOption={buildSetter([ 'include_following_list' ], registrationSetter)} />
                  </li>
                )
              }


              <li>
                <div className='checkbox-options'>
                  <b>{registration.data.registration_setup.label}</b>

                  <div className='checkbox-container vertical'>
                    {
                      !registrationWithCreditCardEnabled &&
                        <>
                          {
                            'members' in registration.data.registration_setup.min_max_members_guests &&
                              <div className='members_guests_container'>
                                <span>{ internationalize('leagues.open_registration.without_credit_card.number_of_members') }</span>
                                <div className='min_max'>
                                  <b>Min</b>
                                  <input
                                    type='number'
                                    value={registration.data.registration_setup.min_max_members_guests.members.min}
                                    onChange={e => registrationUpdateMin(e.target.value, 'members') }
                                    onBlur={e => e.target.value.length === 0 && registrationUpdateMin('0', 'members') }
                                  />

                                  <b>Max</b>
                                  <input
                                    type='number'
                                    value={registration.data.registration_setup.min_max_members_guests.members.max}
                                    onChange={e => registrationUpdateMax(e.target.value, 'members') }
                                    onBlur={e => e.target.value.length === 0 && registrationUpdateMax('0', 'members') }
                                  />
                                </div>
                              </div>
                          }

                          {
                            'guests' in registration.data.registration_setup.min_max_members_guests &&
                              <div className='members_guests_container'>
                                <span>{ internationalize('leagues.open_registration.without_credit_card.number_of_guests') }</span>
                                <div className='min_max'>
                                  <b>Min</b>
                                  <input
                                    type='number'
                                    value={registration.data.registration_setup.min_max_members_guests.guests.min}
                                    onChange={e => registrationUpdateMin(e.target.value, 'guests') }
                                    onBlur={e => e.target.value.length === 0 && registrationUpdateMin('0', 'guests') }
                                  />

                                  <b>Max</b>
                                  <input
                                    type='number'
                                    value={registration.data.registration_setup.min_max_members_guests.guests.max}
                                    onChange={e => registrationUpdateMax(e.target.value, 'guests') }
                                    onBlur={e => e.target.value.length === 0 && registrationUpdateMax('0', 'guests') }
                                  />
                                </div>
                              </div>
                          }

                          {
                            youthLeague &&
                              <>
                                <div className='members_guests_container'>
                                  <span>{ internationalize('leagues.open_registration.tabs.youth_registration_setup.number_of_children') }</span>
                                  <div className='min_max'>
                                    <b>Min</b>
                                    <input
                                      type='number'
                                      value={registration.data.registration_setup.min_max_members_guests.children.min}
                                      onChange={e => registrationUpdateMin(e.target.value, 'children', 1) }
                                      onBlur={e => e.target.value.length === 0 && registrationUpdateMin('1', 'children') }
                                    />

                                    <b>Max</b>
                                    <input
                                      type='number'
                                      value={registration.data.registration_setup.min_max_members_guests.children.max}
                                      onChange={e => registrationUpdateMax(e.target.value, 'children', 1) }
                                      onBlur={e => e.target.value.length === 0 && registrationUpdateMax('1', 'children') }
                                    />
                                  </div>
                                </div>

                                <RadioGroup
                                  className='members_and_guests_radio'
                                  {...registration.data.members_and_guests_radio}
                                  setOption={registrationSetter([ 'members_and_guests_radio', 'selected' ])}
                                  vertical={true}
                                />
                              </>
                          }

                          {
                            !atLeastOne &&
                              <b className='error'>{registration.data.registration_setup.min_max_members_guests.error_label}</b>
                          }
                        </>

                    }

                    <Checkbox
                      key='enable_pending_registration'
                      label={registrationSetupOptions.enable_pending_registration.label}
                      selected={registrationSetupOptions.enable_pending_registration.selected}
                      setOption={ registrationSetupOptionsSetter([ 'enable_pending_registration', 'selected' ]) }
                    />

                  {
                    registrationSetupOptions.enable_pending_registration.selected &&
                      <div className='children'>
                        <RadioGroup
                          className='added_golfers'
                          {...registrationSetupOptions.enable_pending_registration.added_golfers}
                          setOption={registrationSetupOptionsSetter([ 'enable_pending_registration', 'added_golfers', 'selected' ])}
                          vertical={true}
                        />
                        {
                          registrationSetupOptions.enable_pending_registration.added_golfers.selected === 'custom_fields' &&
                            <div className='custom_fields'>
                              <GlgSelect
                                items={ registrationSetupOptions.enable_pending_registration.custom_fields.options }
                                value={ registrationSetupOptions.enable_pending_registration.custom_fields.selected }
                                onChange={ this.props.selectCustomField }
                                usePortal
                                menuShouldBlockScroll
                              />
                              {
                                registrationSetupOptions.enable_pending_registration.custom_fields.selected &&
                                  <div className='custom_field_answer'>
                                    <GlgSelect
                                      isMulti
                                      items={ registrationSetupOptions.enable_pending_registration.custom_field_possible_answers }
                                      value={ registrationSetupOptions.enable_pending_registration.custom_field_answer.value }
                                      onChange= { registrationSetupOptionsSetter([ 'enable_pending_registration', 'custom_field_answer', 'value' ]) }
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  </div>
                              }
                            </div>
                        }
                      </div>
                  }
                  </div>
                </div>
              </li>

              { /* Registration dates */ }
              <li>
                <div className='registration_dates_container'>
                  <span>{ registrationDates.open.label }</span>
                  <div>
                    <DatePicker
                      showTimeSelect
                      timeIntervals={60}
                      customInput={<CalendarCustomInput withTime/>}
                      selected={registrationDates.open.value && moment.unix(registrationDates.open.value).toDate()}
                      dateFormat={getDateFormat(dateFormat)}
                      onChange={(date) => {
                        date = date ? moment(date).unix() : date
                        registrationSetter([ 'registration_dates', 'open', 'value' ])(date)
                      }}
                    />
                  </div>
                </div>

                <div className='registration_dates_container'>
                  <span>{ registrationDates.close.label }</span>
                  <div>
                    <DatePicker
                      showTimeSelect
                      timeIntervals={60}
                      customInput={<CalendarCustomInput withTime/>}
                      selected={registrationDates.close.value && moment.unix(registrationDates.close.value).toDate()}
                      dateFormat={getDateFormat(dateFormat)}
                      onChange={(date) => {
                        date = date ? moment(date).unix() : date
                        registrationSetter([ 'registration_dates', 'close', 'value' ])(date)
                      }}
                    />
                  </div>
                </div>

                {
                  ! registrationOpenBeforeClose &&
                  <b className='error'> {internationalize('leagues.open_registration.tabs.youth_registration_setup.opening_date_before_closing')} </b>
                }
              </li>
            </ul>
          </div>
        </div>

        {
          players &&
            <div className='section players'>
              <div className='title'> { players.title } </div>

              <div className='content flex-list-layout'>
                <ul>
                  <li>
                    <CheckboxOptions
                      className='member-eligibility-criteria'
                      {...players.data['member_eligibility_criteria']}
                      setOption={buildSetter([ 'member_eligibility_criteria' ], playersSetter)}
                      conditionalChildrenByKey={{
                        use_gender: {
                          condition: eligibilityOptions.use_gender.selected,
                          value: (
                            <CheckboxOptions
                              className='eligibility-criteria-options'
                              vertical={false}
                              {...eligibilityOptions.use_gender.gender_answers}
                              setOption={buildSetter([ 'use_gender', 'gender_answers' ], eligibilityOptionsSetter)}
                            />
                          ),
                        },

                        use_index_range: {
                          condition: eligibilityOptions.use_index_range.selected,
                          value: (
                            <div className='eligibility-criteria-options'>
                              <LabeledInput label={ eligibilityOptions.use_index_range.min_handicap_index.label } childrenClass='wide' >
                                <input
                                  className='eligibility-min-handicap-index form-control'
                                  value={ eligibilityOptions.use_index_range.min_handicap_index.value }
                                  onChange={ e => eligibilityOptionsSetter([ 'use_index_range', 'min_handicap_index', 'value' ])(e.target.value) }/>
                              </LabeledInput>

                              <LabeledInput label={ eligibilityOptions.use_index_range.max_handicap_index.label } childrenClass='wide' >
                                <input
                                  className='eligibility-max-handicap-index form-control'
                                  value={ eligibilityOptions.use_index_range.max_handicap_index.value }
                                  onChange={ e => eligibilityOptionsSetter([ 'use_index_range', 'max_handicap_index', 'value' ])(e.target.value) }/>
                              </LabeledInput>

                              <CheckboxOptions
                                {...eligibilityOptions.use_index_range.ignore_requirement}
                                setOption={buildSetter([ 'use_index_range', 'ignore_requirement' ], eligibilityOptionsSetter)}
                              />
                            </div>
                          ),
                        },

                        use_age_range: {
                          condition: eligibilityOptions.use_age_range.selected,
                          value: (
                            <div className='eligibility-criteria-options'>
                              <div className={ 'labeled-input-container' }>
                                <label className={ `${eligibilityOptions.use_age_range.age_date.past_date ? 'error' : ''}` }>
                                  { eligibilityOptions.use_age_range.age_date.label }
                                  {
                                    eligibilityOptions.use_age_range.age_date.past_date &&
                                      <GlgTooltipIcon
                                        tooltip={ eligibilityOptions.use_age_range.age_date.past_date_tooltip }
                                      />
                                  }
                                </label>
                                <div className={ 'children' }>
                                  <DatePicker
                                    customInput={<CalendarCustomInput />}
                                    selected={moment.unix(eligibilityOptions.use_age_range.age_date.value).toDate()}
                                    dateFormat='yyyy/MM/dd'
                                    onKeyDown={(e) => { e.preventDefault() }}
                                    onChange={(date) => {
                                        const selectedDate = moment(date)
                                        const now = moment(new Date())

                                        eligibilityOptionsSetter([ 'use_age_range', 'age_date', 'value' ])(selectedDate.unix())
                                        eligibilityOptionsSetter([ 'use_age_range', 'age_date', 'past_date' ])(selectedDate.isBefore(now, 'day'))
                                      }
                                    }
                                  />
                                </div>
                              </div>

                              <LabeledInput label={ eligibilityOptions.use_age_range.min_age.label } childrenClass='wide' >
                                <input
                                  className='eligibility-min-handicap-index form-control'
                                  value={ eligibilityOptions.use_age_range.min_age.value }
                                  onChange={ e => eligibilityOptionsSetter([ 'use_age_range', 'min_age', 'value' ])(e.target.value) }/>
                              </LabeledInput>

                              <LabeledInput label={ eligibilityOptions.use_age_range.max_age.label } childrenClass='wide' >
                                <input
                                  className='eligibility-max-handicap-index form-control'
                                  value={ eligibilityOptions.use_age_range.max_age.value }
                                  onChange={ e => eligibilityOptionsSetter([ 'use_age_range', 'max_age', 'value' ])(e.target.value) }/>
                              </LabeledInput>
                            </div>
                          ),
                        },
                      }}
                    />
                  </li>

                  <li>
                    <a className='blue-normal' href={ links.registration_setup_page.path }> { links.registration_setup_page.name } </a>
                  </li>

                </ul>
              </div>
            </div>
        }

      </div>
    )
  }
}

EventSetupRegistration.propTypes = {
  content: PropTypes.object,
  setData: PropTypes.func,
  save: PropTypes.func,
  selectCustomField: PropTypes.func,
}

EventSetupRegistration.defaultProps = {
  content: {
    registration: {
      title: 'EVENT REGISTRATION',
      data: {},
    },
    dataChanged: false,
  },
}

export default EventSetupRegistration
